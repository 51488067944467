<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备配置</el-breadcrumb-item>
      <el-breadcrumb-item>网关设备配置</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
        <div v-auth="'dtuManagement-add'">
          <el-button type="primary" size="small" @click="addDTU">新增网关设备</el-button>
          &nbsp;
        </div>
            <el-table
        header-row-class-name="my-table-head-style"
        style="margin-top: 0; margin-bottom: 0; flex: 1;margin-top: 14px;"
        height="100%"
                v-loading="spinning"
                :data="data"
                row-key="id"
            >
              <el-table-column type="expand">
                <template slot-scope="props">
                  <el-form label-position="left" class="demo-table-expand">

                    <el-form-item label="dtu设备id">
                      <span>{{ props.row.dtuDeviceId }}</span>
                    </el-form-item>
                    <el-form-item label="dtu版本号">
                      <span>{{ props.row.dtuVersion }}</span>
                    </el-form-item>
                    <el-form-item label="dtu采集频率">
                      <span>{{ props.row.dtuBaudRate }}</span>
                    </el-form-item>
                    <el-form-item label="dtu采集周期">
                      <span>{{ props.row.dtuCollectCycle }}</span>
                    </el-form-item>
                    <el-form-item label="mqtt代理服务器地址">
                      <span>{{ props.row.mqttServiceAddress }}</span>
                    </el-form-item>
                    <el-form-item label="mqtt代理服务器用户名">
                      <span>{{ props.row.mqttServerUsername }}</span>
                    </el-form-item>
                    <el-form-item label="mqtt代理服务器密码">
                      <span>{{ props.row.mqttServerPassword }}</span>
                    </el-form-item>
                    <el-form-item label="mqtt代理服务器端口">
                      <span>{{ props.row.mqttServerPort }}</span>
                    </el-form-item>
                    <el-form-item label="mqtt心跳包开关状态">
                      <span>{{ props.row.mqttHeartbeatEnabled }}</span>
                    </el-form-item>
                    <el-form-item label="mqtt心跳包周期(单位:分钟)">
                      <span>{{ props.row.mqttHearbeatCycle }}</span>
                    </el-form-item>
                    <el-form-item label="mqtt最大心跳包数量">
                      <span>{{ props.row.mqttHearbeatQuantity }}</span>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>
              <el-table-column
                  prop="dtuDeviceId"
                  label="dtu设备id">
              </el-table-column>
              <el-table-column
                  prop="dtuVersion"
                  label="dtu版本号">
              </el-table-column>
              <!-- <el-table-column
                       prop="mqttServiceAddress"
                       min-width="150"
                       label="mqtt代理服务器地址">
               </el-table-column>
               <el-table-column
                       prop="mqttServerUsername"
                       min-width="150"
                       label="mqtt代理服务器用户名">
               </el-table-column>
               <el-table-column
                       prop="mqttServerPassword"
                       min-width="150"
                       label="mqtt代理服务器密码">
               </el-table-column>
               <el-table-column
                       prop="mqttServerPort"
                       min-width="150"
                       label="mqtt代理服务器端口">
               </el-table-column>
               <el-table-column
                       prop="mqttHeartbeatEnabled"
                       min-width="150"
                       label="mqtt心跳包开关状态">
               </el-table-column>
               <el-table-column
                       prop="mqttHearbeatCycle"
                       min-width="180"
                       label="mqtt心跳包周期(单位:分钟)">
               </el-table-column>
               <el-table-column
                       prop="mqttHearbeatQuantity"
                       min-width="150"
                       label="mqtt最大心跳包数量">
               </el-table-column>
               <el-table-column
                       prop="dtuBaudRate"
                       min-width="100"
                       label="dtu采集频率">
               </el-table-column>
               <el-table-column
                       prop="dtuCollectCycle"
                       min-width="100"
                       label="dtu采集周期">
               </el-table-column>-->
              <el-table-column
                  prop="customDtuName"
                  label="dtu名字">
              </el-table-column>
              <el-table-column
                  prop="customDtuAddress"
                  label="dtu位置">
              </el-table-column>
              <el-table-column
                  fixed="right"
                  width="200"
                  label="操作"
                  align="center"
                  v-if="authVerify.verify('dtuManagement-edit,dtuManagement-delete')"
              >
                <template slot-scope="scope">
                  <el-button icon="el-icon-edit-outline" size="mini" v-auth="'dtuManagement-edit'"
                             @click="editFarmArea(scope.row)">编辑
                  </el-button>
                  <el-button icon="el-icon-delete" size="mini" v-auth="'dtuManagement-delete'"
                             @click="deleteFarmArea(scope.row)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
      </el-card>
    <!-- 添加 -->
    <template>
      <div>
        <a-drawer
            title="新增网关设备(DTU)"
            width="800"
            placement="right"
            :closable="false"
            :visible="addDTUVisible"
            @close="addDTUVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model :model="DTUFormValue" ref="DTURefFormAdd"
                          :rules="DTURules" :label-col="{ span: 7 }"
                          :wrapper-col="{ span: 14 }">
              <a-form-model-item label="dtu名字" prop="customDtuName">
                <a-input
                    v-model="DTUFormValue.customDtuName"
                />
              </a-form-model-item>
              <a-form-model-item label="dtu位置" prop="customDtuAddress">
                <a-input
                    v-model="DTUFormValue.customDtuAddress"
                />
              </a-form-model-item>
              <a-form-model-item label="dtu设备id" prop="dtuDeviceId">
                <a-input
                    v-model="DTUFormValue.dtuDeviceId"
                />
              </a-form-model-item>
              <a-form-model-item label="dtu版本号" prop="dtuVersion">
                <a-input
                    v-model="DTUFormValue.dtuVersion"
                />
              </a-form-model-item>
              <a-form-model-item label="dtu采集频率" prop="dtuBaudRate">
                <a-input
                    v-model="DTUFormValue.dtuBaudRate"
                />
              </a-form-model-item>
              <a-form-model-item label="dtu采集周期" prop="dtuCollectCycle">
                <a-input
                    v-model="DTUFormValue.dtuCollectCycle"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt代理服务器地址" prop="mqttServiceAddress">
                <a-input
                    v-model="DTUFormValue.mqttServiceAddress"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt代理服务器用户名" prop="mqttServerUsername">
                <a-input
                    v-model="DTUFormValue.mqttServerUsername"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt代理服务器密码" prop="mqttServerPassword">
                <a-input
                    v-model="DTUFormValue.mqttServerPassword"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt代理服务器端口" prop="mqttServerPort">
                <a-input
                    v-model="DTUFormValue.mqttServerPort"
                    type="number"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt心跳包开关状态" prop="mqttHeartbeatEnabled">
                <a-input
                    v-model="DTUFormValue.mqttHeartbeatEnabled"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt心跳包周期(单位:分钟)" prop="mqttHearbeatCycle">
                <a-input
                    v-model="DTUFormValue.mqttHearbeatCycle"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt最大心跳包数量" prop="mqttHearbeatQuantity">
                <a-input
                    v-model="DTUFormValue.mqttHearbeatQuantity"
                />
              </a-form-model-item>

            </a-form-model>
          </div>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="addDTUVisible = false">
              取消
            </a-button>
            <a-button type="primary" @click="toAddDTU">
              确认
            </a-button>
          </div>

        </a-drawer>
      </div>
    </template>
    <!-- 编辑 -->
    <template>
      <div>
        <a-drawer
            title="编辑网关设备(DTU)"
            width="800"
            placement="right"
            :closable="false"
            :visible="editDTUVisible"
            @close="editDTUVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model :model="DTUFormValue" ref="DTURefFormEdit"
                          :rules="DTURules" :label-col="{ span: 7 }"
                          :wrapper-col="{ span: 14 }">
              <a-form-model-item label="dtu名字" prop="customDtuName">
                <a-input
                    v-model="DTUFormValue.customDtuName"
                />
              </a-form-model-item>
              <a-form-model-item label="dtu位置" prop="customDtuAddress">
                <a-input
                    v-model="DTUFormValue.customDtuAddress"
                />
              </a-form-model-item>
              <a-form-model-item label="dtu设备id" prop="dtuDeviceId">
                <a-input
                    v-model="DTUFormValue.dtuDeviceId"
                />
              </a-form-model-item>
              <a-form-model-item label="dtu版本号" prop="dtuVersion">
                <a-input
                    v-model="DTUFormValue.dtuVersion"
                />
              </a-form-model-item>
              <a-form-model-item label="dtu采集频率" prop="dtuBaudRate">
                <a-input
                    v-model="DTUFormValue.dtuBaudRate"
                />
              </a-form-model-item>
              <a-form-model-item label="dtu采集周期" prop="dtuCollectCycle">
                <a-input
                    v-model="DTUFormValue.dtuCollectCycle"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt代理服务器地址" prop="mqttServiceAddress">
                <a-input
                    v-model="DTUFormValue.mqttServiceAddress"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt代理服务器用户名" prop="mqttServerUsername">
                <a-input
                    v-model="DTUFormValue.mqttServerUsername"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt代理服务器密码" prop="mqttServerPassword">
                <a-input
                    v-model="DTUFormValue.mqttServerPassword"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt代理服务器端口" prop="mqttServerPort">
                <a-input
                    v-model="DTUFormValue.mqttServerPort"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt心跳包开关状态" prop="mqttHeartbeatEnabled">
                <a-input
                    v-model="DTUFormValue.mqttHeartbeatEnabled"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt心跳包周期(单位:分钟)" prop="mqttHearbeatCycle">
                <a-input
                    v-model="DTUFormValue.mqttHearbeatCycle"
                />
              </a-form-model-item>
              <a-form-model-item label="mqtt最大心跳包数量" prop="mqttHearbeatQuantity">
                <a-input
                    v-model="DTUFormValue.mqttHearbeatQuantity"
                />
              </a-form-model-item>

            </a-form-model>
          </div>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="editDTUVisible = false">
              取消
            </a-button>
            <a-button type="primary" @click="toEditDTU">
              确认
            </a-button>
          </div>

        </a-drawer>
      </div>
    </template>

  </div>
</template>

<script>
export default {
  data() {
    return {
      DTUFormValue: {
        id: '',
        dtuDeviceId: '',
        dtuVersion: '',
        dtuBaudRate: '',
        dtuCollectCycle: '',
        mqttServiceAddress: '',
        mqttServerUsername: '',
        mqttServerPassword: '',
        mqttServerPort: '',
        mqttHeartbeatEnabled: '',
        mqttHearbeatCycle: '',
        mqttHearbeatQuantity: '',
        customDtuName: '',
        customDtuAddress: '',
      },
      DTURules: {
        dtuDeviceId: [
          {required: true, message: '请输入设备id!', trigger: 'blur'},
        ], mqttServiceAddress: [
          {required: true, message: '请输入mqtt代理服务器地址!', trigger: 'blur'},
        ], mqttServerUsername: [
          {required: true, message: '请输入mqtt代理服务器用户名!', trigger: 'blur'},
        ], mqttServerPassword: [
          {required: true, message: '请输入mqtt代理服务器密码!', trigger: 'blur'},
        ], mqttServerPort: [
          {required: true, message: '请输入mqtt代理服务器端口!', trigger: 'blur'},
        ], customDtuName: [
          {required: true, message: '请输入网关设备名字!', trigger: 'blur'},
        ],
      },
      spinning: true,
      screenWidth: document.documentElement.clientWidth,//屏幕宽度
      screenHeight: document.documentElement.clientHeight - 50,//屏幕高度
      addDTUVisible: false,
      editDTUVisible: false,
      data: [],
    }
  },
  watch: {
    'screenWidth': function (val) { //监听屏幕宽度变化
      console.log(val);
    },
    'screenHeight': function (val) { //监听屏幕高度变化
      console.log(val);
    },
  },
  mounted() {
    const _this = this;
    window.onresize = function () { // 定义窗口大小变更通知事件
      _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
      _this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
    };
  },
  created() {
    this.getDTUData();
  },
  filters: {
    StateFiltr: function (value) {
      if (!value) return "";
      if (value === 'USABLE') {
        return "正常";
      } else if (value === 'LOCK') {
        return "锁定";
      } else if (value === 'DISABLED') {
        return "禁用";
      } else {
        return "禁用";
      }
    },
    StateTypeFiltr: function (value) {
      if (!value) return "";
      if (value === 'USABLE') {
        return "success";
      } else if (value === 'LOCK') {
        return "锁定";
      } else if (value === 'DISABLED') {
        return "warning";
      } else {
        return "danger";
      }
    },
  },
  computed: {
    pageHeight: function () {
      let height = document.documentElement.clientHeight;
      return height - 50;
    }
  },
  methods: {
    getDTUData() {
      this.http.device.getDtuModelInstallInfoList().then(res=>{
        if (res.data.status === 200) {
              this.spinning = false;
              this.data = res.data.data;
            } else {
              this.$message.warning('数据获取失败');
            }
      })
    },
    deleteFarmArea(key) {
      let self = this;
      this.$confirm({
        title: '确定删除吗?',
        content: '',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          this.http.device.deleteDtuModelInstallInfo({
              id: key.id
          }).then(res=>{
            if (res.data.status === 200) {
                  this.$message.success(res.data.msg);
                  self.getDTUData();
                } else {
                  this.$message.error(res.data.msg);
                }
          })
        },
        onCancel() {
        },
      });
    },
    addDTU() {
      this.addDTUVisible = true;
      this.cleanDTU();
        this.$refs.DTURefFormAdd.resetFields()
    },
    toAddDTU() {
      this.$refs.DTURefFormAdd.validate(valid => {
        if (valid) {
          this.http.device.createDtuModelInstallInfo(this.DTUFormValue)
              .then(res => {
                if (res.data.status === 200) {
                  this.$message.success(res.data.msg);
                  this.addDTUVisible = false;
                } else {
                  this.$message.warning(res.data.msg);
                }
                this.getDTUData();
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    editFarmArea(record) {
      console.log(record);
      this.cleanDTU();
      this.DTUFormValue = {
        id: record.id,
        dtuDeviceId: record.dtuDeviceId,
        dtuVersion: record.dtuVersion,
        dtuBaudRate: record.dtuBaudRate,
        dtuCollectCycle: record.dtuCollectCycle,
        mqttServiceAddress: record.mqttServiceAddress,
        mqttServerUsername: record.mqttServerUsername,
        mqttServerPassword: record.mqttServerPassword,
        mqttServerPort: record.mqttServerPort,
        mqttHeartbeatEnabled: record.mqttHeartbeatEnabled,
        mqttHearbeatCycle: record.mqttHearbeatCycle,
        mqttHearbeatQuantity: record.mqttHearbeatQuantity,
        customDtuName: record.customDtuName,
        customDtuAddress: record.customDtuAddress,
      };
      this.editDTUVisible = true;
        this.$refs.DTURefFormEdit.resetFields()
    },
    cleanDTU() {
      this.DTUFormValue = {
        id: '',
        dtuDeviceId: '',
        dtuVersion: '',
        dtuBaudRate: '',
        dtuCollectCycle: '',
        mqttServiceAddress: '',
        mqttServerUsername: '',
        mqttServerPassword: '',
        mqttServerPort: '',
        mqttHeartbeatEnabled: '',
        mqttHearbeatCycle: '',
        mqttHearbeatQuantity: '',
        customDtuName: '',
        customDtuAddress: '',
      };
    },

    toEditDTU() {
      this.$refs.DTURefFormEdit.validate(valid => {
        if (valid) {
          this.http.device.updateDtuModelInstallInfo(this.DTUFormValue)
              .then(res => {
                if (res.data.status === 200) {
                  this.$message.success(res.data.msg);
                  this.editDTUVisible = false;
                } else {
                  this.$message.warning(res.data.msg);
                }
                this.getDTUData();
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

  },
}
</script>

<style scoped>
/*滚动条整体样式*/
.scroll_bar::-webkit-scrollbar {
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
}

.scroll_bar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #cecece;
}

.scroll_bar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}

.demo-table-expand >>> .el-form-item__label {
  color: #99a9bf;
  font-size: 8px;
}

.demo-table-expand >>> .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
</style>
